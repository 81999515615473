<template>
  <div :class="this.gameGradient">
    <div class="stats-content">
      <div class="stats-title">{{ this.gameName }}</div>
      <div class="stats-number" v-html="this.numGamesForPrice"></div>
    </div>
  </div>
</template>

<script>

import {config} from "@/config/config";
import {camelCase} from "lodash";

export default {
  name: "CarrotCalculatorGameInfo",
  props: {
    gameInfo: {
      type: Object,
      required: true
    },
    game: {
      type: String,
      required: true
    },
    numCarrots: {
      type: Number,
      required: true,
    },
    carrotPrice: {
      type: Number,
      required: true,
    },
    isEmptyState: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    gameName() {
      return this.$t("games.games." + camelCase(this.game) + ".name");
    },
    gameData() {
      return config.games.props[camelCase(this.game)];
    },
    gameGradient() {
      const gameGradient = this.gameData.gradient;
      return `widget widget-stats bg-gradient-${gameGradient} m-5 rm-widget`
    },
    numGamesForPrice() {
      if (this.isEmptyState) {
        return "<i class='fa fa-question'/>";
      }

      // Leave this '==' since we want to compare 0.00 == 0
      if (this.averagePrice == 0) {
        return "&infin;"
      }

      return (this.numCarrots / this.averagePrice).toFixed(0);
    },
    averagePrice() {
      if(this.gameInfo.config) {
        const keys = Object.keys(this.gameInfo.config)
        if(keys.length === 0) {
          return 0;
        }

        let numAllPrices = 0;
        let sumAllPrices = 0;
        for(let h = 0; h < keys.length; h++) {
          const obj = this.gameInfo.config[keys[h]]
          const numPrices = obj["values"].length;
          numAllPrices += numPrices;
          for(let i = 0; i < numPrices; i++){
            sumAllPrices += obj["values"][i]["prices"]["current"]
          }
        }

        if(numAllPrices === 0 || sumAllPrices === 0) {
          return 0;
        }

        return (sumAllPrices / numAllPrices).toFixed(2);
      }

      return 0;
    }
  }
}
</script>

<style scoped>

</style>
